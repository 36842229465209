<template>
  <div>
    <TraceMobile
      v-if="isSmallScreen"
      :linkClick="true"
      :currentVersion="true"
      :showToggle="false"
      :customerWindow="customerWindow"
      :isAddReviewVisible="isAddReviewVisible"
      :isAuthorizedToChangeReviewStatus="false"
    />
    <Trace
      v-else
      :linkClick="true"
      :currentVersion="true"
      :showToggle="false"
      :customerWindow="customerWindow"
      :isAddReviewVisible="isAddReviewVisible"
      :isAuthorizedToChangeReviewStatus="false"
    />
  </div>
</template>

<script>
import Trace from "../../components/Inquiry/Trace.vue";
import TraceMobile from "../../components/Inquiry/TraceMobile.vue";
import * as conf from "../../conf.yml";

export default {
  components: { Trace, TraceMobile },
  data: () => ({
    styleNumber: null,
    customerWindow: true,
    size: window.innerWidth,
  }),
  computed: {
    isSmallScreen() {
      return this.size <= 750;
    },
    isAddReviewVisible() {
      return !conf.default.underMaintenance;
    },
  },
  created() {
    window.addEventListener("resize", this.screenSizeHandler);
    if (
      window.location.hash.substring(
        0,
        window.location.hash.lastIndexOf("/")
      ) !== "#/preview/trace"
    ) {
      this.customerWindow = false;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.screenSizeHandler);
  },
  methods: {
    screenSizeHandler(e) {
      this.size = window.innerWidth;
      return this.size;
    },
  },
};
</script>
